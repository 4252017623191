import { BankIcon } from './icons/bank';
import { CalculatorIcon } from './icons/calculator';
import { House1Icon } from './icons/house-1';
import { House2Icon } from './icons/house-2';
import { House3Icon } from './icons/house-3';
import { House4Icon } from './icons/house-4';
import { House5Icon } from './icons/house-5';
import { House6Icon } from './icons/house-6';
import { RadioSelect } from './icons/radio-select';
import { UsersIcon } from './icons/users';

export const MultiColorIcon = {
  House1: House1Icon,
  House2: House2Icon,
  House3: House3Icon,
  House4: House4Icon,
  House5: House5Icon,
  House6: House6Icon,
  Calculator: CalculatorIcon,
  RadioSelect: RadioSelect,
  Users: UsersIcon,
  Bank: BankIcon,
};

export type MultiColorIconKey = keyof typeof MultiColorIcon;
