import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';
import flagsmith from 'flagsmith/isomorphic';
import { FlagsmithProvider } from 'flagsmith/react';
import { IState } from 'flagsmith/types';
import { ThemeProvider } from 'styled-components';

import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { Session } from 'next-auth';
// import { SessionProvider, getSession } from 'next-auth/react';
import NextApp, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';

import { EnvironmentMarker, Spinner, ToastifyStyle } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { config, handleErrors } from '@hl-portals/helpers';

import { ModalContainer, ModalProvider } from '@hl-portals/hooks';

import './styles.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      onError: (e: AxiosError) => {
        handleErrors(e);
      },
    },
    mutations: {
      onError: (e: AxiosError) => {
        handleErrors(e);
      },
    },
  },
});

Sentry.init({
  enabled: ['production', 'staging'].includes(config.environmentName),
  dsn: config.sentryDsn,
  tracesSampleRate: 0,
  environment: config.environmentName,
});

function App({
  Component,
  pageProps,
  flagsmithState,
  err,
}: AppProps & { err: Error; flagsmithState: IState }) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const registerPageLoad = () => setLoaded(true);
    window.addEventListener('load', registerPageLoad);

    const timeout = setTimeout(registerPageLoad, 3000);

    return () => {
      window.removeEventListener('load', registerPageLoad);
      clearTimeout(timeout);
    };
  }, []);

  const SHOW_STAGING_BANNER =
    typeof window !== 'undefined' && window.location.href.includes('staging');

  return (
    <>
      <Head>
        <title>Equity App</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 maximum-scale=1.0, user-scalable=no, minimal-ui"
        />
      </Head>
      <main className="app">
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          draggable
          closeOnClick
          pauseOnHover
          hideProgressBar
          theme="dark"
          transition={Slide}
          icon={false}
        />

        <FlagsmithProvider flagsmith={flagsmith} serverState={flagsmithState}>
          <QueryClientProvider client={queryClient}>
            <ModalProvider>
              <ThemeProvider theme={theme}>
                <ToastifyStyle />

                {loaded ? (
                  <>
                    {SHOW_STAGING_BANNER && <EnvironmentMarker />}
                    <Component {...pageProps} err={err} />
                  </>
                ) : (
                  <Spinner xl mt="100px" />
                )}
                <ModalContainer />
              </ThemeProvider>
            </ModalProvider>
          </QueryClientProvider>
        </FlagsmithProvider>
      </main>
    </>
  );
}

const EquityApp = (
  props: AppProps & { err: Error; flagsmithState: IState }
) => {
  return (
    // @ts-ignore
    // <SessionProvider session={props?.pageProps?.session}>
    <App {...props} flagsmithState={props?.flagsmithState} />
    // </SessionProvider>
  );
};

EquityApp.getInitialProps = async (appContext: AppContext) => {
  const nextProps = await NextApp.getInitialProps(appContext);

  // const session = (await getSession({ req: appContext.ctx.req })) as
  //   | (Session & { userId: string })
  //   | null;

  await flagsmith.init({
    environmentID: config.flagsmithEnvironmentId,
    // identity: session?.userId,
    cacheFlags: false,
  });

  return { ...nextProps, flagsmithState: flagsmith.getState() };
};

export default EquityApp;
